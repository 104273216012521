import { AppButton, ButtonsType, Popup } from 'components';
import InputField, { InputFieldType } from 'components/InputField';
import { useForm } from 'react-hook-form';

import styles from './styles.module.scss';

interface IDictionaryPopupProps {
  onClose: () => void;
  defaultValue: string;
  onSave: (value: string) => void;
}

type CreateDictionaryFormValues = {
  postsDictionary: string;
};

const DictionaryPopup = (props: IDictionaryPopupProps) => {
  const { onClose, defaultValue, onSave } = props;
  const { register, control, watch } = useForm<CreateDictionaryFormValues>({
    defaultValues: {
      postsDictionary: defaultValue
    }
  });

  const value = watch('postsDictionary');

  const onSaveClick = () => {
    onSave(value);
    onClose();
  };

  return (
    <Popup title="Reddit Posts dictionary" onClose={onClose}>
      <div>
        <InputField
          control={control}
          defaultValue={defaultValue}
          name="postsDictionary"
          type={InputFieldType.textarea}
          register={register}
          errors={undefined}
          placeholder=""
          validate={{}}
          rows={15}
        />
        <div className={styles.actions}>
          <AppButton btnType={ButtonsType.simple} label="Cancel" onClick={onClose} htmlType="button" />
          <AppButton
            btnType={ButtonsType.outlined}
            label="Save dictionary"
            onClick={() => onSaveClick()}
            htmlType="button"
          />
        </div>
      </div>
    </Popup>
  );
};

export default DictionaryPopup;
