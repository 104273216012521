import classNames from 'classnames';
import { Layout, Spinner } from 'components';
import { useAppDispatch, useAppSelector } from 'hooks';
import StockFilterHeader from 'modules/StockFilterHeader';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { getUiConfig, toggleAvailableStock } from 'store/slices/authSlice';
import { sortArrByKey } from 'utils';

import styles from './styles.module.scss';
import { generalUiConfigComponents } from './ui-config';

const HomePage: React.FC<{ blurContent?: boolean }> = ({ blurContent }) => {
  const uiConfig = useAppSelector(getUiConfig);
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [stockProcessed, setStockProcessed] = useState(false);
  const [searchParams] = useSearchParams();
  const selectedStock = searchParams.get('activeStock');

  useEffect(() => {
    if (stockProcessed || !selectedStock) {
      setStockProcessed(true);
      return;
    }

    dispatch(toggleAvailableStock(selectedStock));
    navigate(location.pathname, { replace: true });
    setStockProcessed(true);
  }, [dispatch, location.pathname, navigate, selectedStock, stockProcessed]);

  if (!uiConfig || !stockProcessed) {
    return <Spinner />;
  }

  const {
    config: {
      homepage: { general }
    }
  } = uiConfig;

  const sortedGeneral = sortArrByKey(general, 'order');

  return (
    <Layout blurContent={blurContent}>
      <>
        <div className={classNames(styles.wrapper, styles.topWrapper)}>
          <div className={styles.companyFilters}>
            <StockFilterHeader withChangeUIOrder />
          </div>
          {sortedGeneral.map(({ uiKey, enabled }) => {
            const Component = generalUiConfigComponents[uiKey];

            if (!Component || !enabled) {
              return null;
            }

            return <Component key={uiKey} />;
          })}
        </div>
      </>
    </Layout>
  );
};
export default HomePage;
