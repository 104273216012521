import { Archive as ArchiveIcon, Download as DownloadIcon, Send as SendIcon } from '@mui/icons-material';
import { ListItemIcon, ListItemText, MenuItem } from '@mui/material';
import { ContextMenu } from 'components';
import { API_ROUTES } from 'const';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ToastifyService from 'services/ToastifyService';
import { IReport, ReportStatusEnum } from 'types';
import { fetchWithConfig } from 'utils';

import SendReportPopup from './SendReportPopup';
import styles from './styles.module.scss';

interface IReportActionsMenuProps {
  report: IReport;
  refresh: () => void;
}

const ReportActionsMenu: React.FC<IReportActionsMenuProps> = ({ report, refresh }) => {
  const { _id, name, status } = report;
  const { t } = useTranslation();
  const [openSendPopup, setOpenSendPopup] = useState(false);

  const onDownload = async () => {
    const res: Blob = await fetchWithConfig({ url: `${API_ROUTES.DOWNLOAD_REPORT}/${_id}`, responseType: 'blob' });
    const url = URL.createObjectURL(res);
    const link = document.createElement('a');
    link.href = url;
    link.download = `${name}.pdf`;
    link.target = '_self';
    link.click();
    link.remove();
  };

  const onArchive = async () => {
    try {
      const res = await fetchWithConfig({ url: `${API_ROUTES.REPORTS}/archive/${_id}`, method: 'POST' });
      ToastifyService.setToast(res.message as string, 'success');
      refresh();
    } catch (error: any) {
      ToastifyService.setToast(error, 'error');
    }
  };

  return (
    <>
      <div className={styles.wrapper}>
        <ContextMenu>
          {status === ReportStatusEnum.COMPLETED && (
            <MenuItem onClick={onDownload}>
              <ListItemIcon>
                <DownloadIcon />
              </ListItemIcon>
              <ListItemText>{t('pages.reports.actions.download')}</ListItemText>
            </MenuItem>
          )}
          {status === ReportStatusEnum.COMPLETED && (
            <MenuItem onClick={() => setOpenSendPopup(true)}>
              <ListItemIcon>
                <SendIcon />
              </ListItemIcon>
              <ListItemText>{t('pages.reports.actions.send')}</ListItemText>
            </MenuItem>
          )}
          <MenuItem onClick={onArchive}>
            <ListItemIcon>
              <ArchiveIcon />
            </ListItemIcon>
            <ListItemText>{t('pages.reports.actions.archive')}</ListItemText>
          </MenuItem>
        </ContextMenu>
      </div>
      {openSendPopup && <SendReportPopup onClose={() => setOpenSendPopup(false)} report={report} />}
    </>
  );
};

export default ReportActionsMenu;
