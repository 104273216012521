import { AutoCompleteList, CustomSelect } from 'components';
import AppButton, { ButtonsType } from 'components/AppButton';
import InputField, { InputFieldType } from 'components/InputField';
import { API_ROUTES, APP_ROUTS } from 'const';
import useOptionUsers from 'hooks/useOptionUsers';
import useTeams from 'hooks/useTeams';
import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { required } from 'services/InputFIeldsValidator/rules';
import ToastifyService from 'services/ToastifyService';
import { IStock, ResponseWithPagination } from 'types';
import { catchRequestErrors, fetchWithConfig } from 'utils';

import ContentBlock from '../../ContentBlock';
import DashboardLayout from '../../DashboardLayout';
import SubmitPopup from './SubmitPopup';
import styles from './styles.module.scss';

export interface FormValues {
  stock: string;
  title: string;
  topics: string[];
  team: string;
  emails: string[];
}

export const CreateAnalystAlert = () => {
  const {
    register,
    handleSubmit,
    setError,
    control,
    reset,
    setValue,
    watch,
    formState: { errors }
  } = useForm<FormValues>({
    defaultValues: {
      title: '',
      topics: [''],
      team: '',
      emails: []
    }
  });

  const [loading, setLoading] = useState<boolean>(false);
  const [showSubmitPopup, setShowSubmitPopup] = useState<boolean>(false);
  const { teamsData, teamsLoading } = useTeams();
  const allFormData = watch();
  const team = watch('team');

  const { usersOptionData, usersOptionLoading } = useOptionUsers({ teamId: team }, !!team);
  const userEmailsOptionsData = useMemo(
    () => usersOptionData?.map((user) => ({ _id: user.name, name: user.name })) || [],
    [usersOptionData]
  );

  const topics = watch('topics');
  const atLeastOneTopic = topics.some((topic) => topic.trim() !== '');
  const { t } = useTranslation();

  const formatSuggestion = (data: ResponseWithPagination<IStock>) => {
    return data.list.map((item: any) => ({
      value: item.symbol,
      name: `${item.name} (${item.symbol})`
    }));
  };

  const chooseStock = (value: string, responseData: ResponseWithPagination<IStock>) => {
    const stock = responseData.list.find(({ symbol }) => symbol === value)!;
    setValue('stock', stock.symbol);
    setError('stock', {});
  };

  useEffect(() => {
    setValue('emails', []);
  }, [team]);

  const onSubmit = () => {
    setShowSubmitPopup(true);
  };

  const onSubmitApprove = async () => {
    try {
      setLoading(true);
      const responseData = await fetchWithConfig({
        url: API_ROUTES.CREATE_ANALYST_ALERT,
        method: 'POST',
        data: {
          ...allFormData
        }
      });

      ToastifyService.setToast('Analyst Report created successfully.', 'success');
      reset();
      setLoading(false);
      setShowSubmitPopup(false);
    } catch (error: any) {
      catchRequestErrors(error, setError, () => {}, setLoading);
    }
  };

  return (
    <DashboardLayout
      title={t('pages.admin.analystAlert.createTitle')}
      onSubmit={handleSubmit(onSubmit)}
      actionBtn={<AppButton isLoading={loading} label={'Create'} btnType={ButtonsType.signIn} />}
    >
      <>
        <ContentBlock>
          <>
            <AutoCompleteList
              searchKey="name"
              autocompleteInput={false}
              closeOnSelect
              onClick={chooseStock}
              url={API_ROUTES.STOCKS}
              placeholder={t('pages.admin.analystAlert.searchStockPlaceholder')}
              formatSuggestion={formatSuggestion}
            />
            <div className={styles.stockField}>
              <InputField
                disabled
                label="Selected Stock"
                control={control}
                defaultValue=""
                name="stock"
                type={InputFieldType.text}
                register={register}
                validate={{ required }}
                errors={errors.stock}
                placeholder="Choose stock in autocomplete above"
              />
            </div>
          </>
        </ContentBlock>
        <ContentBlock>
          <InputField
            label="Alert Title"
            control={control}
            defaultValue=""
            name="title"
            type={InputFieldType.text}
            register={register}
            validate={{ required }}
            errors={errors.title}
            placeholder="Enter title value"
          />
        </ContentBlock>
        <ContentBlock>
          <div>
            <div className={styles.topicsList}>
              {topics.map((topic, index) => {
                return (
                  <div className={styles.topicRow} key={index}>
                    <InputField
                      key={index}
                      label={`Topic ${index + 1}`}
                      control={control}
                      defaultValue=""
                      validate={{}}
                      name={`topics.${index}`}
                      type={InputFieldType.textarea}
                      register={register}
                      validateText={atLeastOneTopic ? undefined : 'At least one topic is required'}
                      errors={errors.topics ? errors.topics[index] : undefined}
                      placeholder="Enter topic value"
                    />
                    <AppButton
                      htmlType="button"
                      disabled={topics.length <= 1}
                      btnType={ButtonsType.simple}
                      label="Delete"
                      onClick={() => {
                        const updatedTopics = topics.filter((_, i) => i !== index); // Удаляем по индексу
                        setValue('topics', updatedTopics);
                      }}
                    />
                  </div>
                );
              })}
            </div>
            <div className={styles.addTopicButtonWrapper}>
              <AppButton
                htmlType="button"
                btnType={ButtonsType.outlined}
                onClick={() => setValue('topics', [...topics, ''])}
                label="Add another Topic"
              />
            </div>
          </div>
        </ContentBlock>
        <ContentBlock>
          <CustomSelect
            loading={teamsLoading || loading}
            required
            errors={errors.team}
            placeholder="Select Team"
            name="team"
            register={register}
            control={control}
            defaultValue=""
            data={teamsData! || []}
          />
        </ContentBlock>
        {!!team && (
          <ContentBlock>
            <CustomSelect
              loading={usersOptionLoading || loading}
              required
              errors={errors.emails as any}
              placeholder="Select Users"
              name="emails"
              register={register}
              control={control}
              defaultValue={[]}
              data={userEmailsOptionsData! || []}
              isMultiple
            />
          </ContentBlock>
        )}
        <SubmitPopup
          loading={loading}
          data={allFormData}
          show={showSubmitPopup}
          onClose={() => setShowSubmitPopup(false)}
          onSubmit={onSubmitApprove}
        />
      </>
    </DashboardLayout>
  );
};
