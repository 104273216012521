import classNames from 'classnames';
import React from 'react';

import RangePicker from '../../../../components/RangePicker';
import { CalendarIcon } from '../../../../components/SvgComponents';
import { useAppSelector } from '../../../../hooks';
import { getFiltersDataRangeLoadingState, getRangeOpenState } from '../../../../store/slices/filtersSlice';
import { DatePickerFilterConfig, FiltersDateRangeType } from '../../../../types';
import styles from './styles.module.scss';

type DateFilterItemProps = {
  filterConfig: DatePickerFilterConfig;
  minDate?: Date;
  initialRange?: Date[];
  forceLoading?: boolean;
};

const DateFilterItem: React.FC<DateFilterItemProps> = ({
  filterConfig: { label, type: filterType, isActive, onClose, onClick },
  minDate,
  initialRange,
  forceLoading
}) => {
  const datepickerApiLoading = useAppSelector(getFiltersDataRangeLoadingState);
  const loadingState = typeof forceLoading === 'boolean' ? forceLoading : datepickerApiLoading;
  const isOpen = useAppSelector(getRangeOpenState);

  return (
    <div className={styles.container}>
      <button
        onClick={onClick}
        className={classNames(styles.wrapper, { [styles.active]: isActive })}
        disabled={loadingState}
      >
        {filterType === FiltersDateRangeType.range && minDate && initialRange ? <CalendarIcon /> : label}
      </button>
      {filterType === FiltersDateRangeType.range && isOpen && minDate && initialRange && (
        <div className={styles.picker}>
          <RangePicker minDate={minDate} isOpen={isOpen} onBtnClose={onClose!} initialRange={initialRange} />
        </div>
      )}
    </div>
  );
};

export default DateFilterItem;
