import CancelIcon from '@mui/icons-material/Cancel';
import { AppButton, ButtonsType, Popup } from 'components';
import InputField, { InputFieldType } from 'components/InputField';
import { useForm } from 'react-hook-form';

import styles from './styles.module.scss';

interface IListsPopupProps {
  onClose: () => void;
  defaultValue: string[];
  onSave: (value: string[]) => void;
  title: string;
}

type CreateListForm = {
  list: string[];
  newItemValue: string;
};

const ListPopup = (props: IListsPopupProps) => {
  const { onClose, defaultValue, onSave, title } = props;
  const {
    register,
    control,
    setValue,
    watch,
    formState: { errors },
    setError
  } = useForm<CreateListForm>({
    defaultValues: {
      list: defaultValue,
      newItemValue: ''
    }
  });

  const newItemValue = watch('newItemValue');
  const list = watch('list');

  const onSaveClick = () => {
    onSave(list);
    onClose();
  };

  const onAddNew = () => {
    const newValues = [...list, newItemValue];
    const isUniqValue = newValues
      .map((filter) => filter.trim())
      .every((filter, index) => newValues.indexOf(filter) === index);

    if (!isUniqValue) {
      setError('newItemValue', { message: 'Each value should be unique' });
      return;
    }

    setError('newItemValue', { message: '' });
    setValue('list', newValues);
    setValue('newItemValue', '');
  };

  const onDelete = (index: number) => {
    const updated = list.filter((_, i) => i !== index);
    setValue('list', updated);
  };

  return (
    <Popup title={title} onClose={onClose}>
      <div>
        <div className={styles.newValueWrapper}>
          <InputField
            control={control}
            defaultValue=""
            name="newItemValue"
            type={InputFieldType.text}
            register={register}
            validate={{}}
            errors={errors.newItemValue}
            placeholder=""
          />
          <AppButton
            disabled={!newItemValue.trim()}
            onClick={onAddNew}
            btnType={ButtonsType.outlined}
            label="Add to list"
            htmlType="button"
          />
        </div>
        <div className={styles.subredditList}>
          {list.map((item, index) => {
            return (
              <div className={styles.listItem} key={index}>
                <div className={styles.listItemValue}>{item}</div>
                <div className={styles.deleteFromList} onClick={() => onDelete(index)}>
                  <CancelIcon />
                </div>
              </div>
            );
          })}
        </div>
        <div className={styles.actions}>
          <AppButton btnType={ButtonsType.simple} label="Cancel" onClick={onClose} htmlType="button" />
          <AppButton btnType={ButtonsType.outlined} label="Save List" onClick={onSaveClick} htmlType="button" />
        </div>
      </div>
    </Popup>
  );
};

export default ListPopup;
