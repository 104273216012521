import { Avatar } from '@mui/material';
import { CrudTable } from 'components';
import { API_ROUTES } from 'const';
import { ExecutiveSummary } from 'modules';
import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { ExecutiveSummaryDaysFilter, IStock, ITableColumns } from 'types';
import { numberWithCommas } from 'utils';

import styles from './styles.module.scss';

interface IStocksTableProps {
  filterDay: ExecutiveSummaryDaysFilter;
}

const StocksTable = (props: IStocksTableProps) => {
  const { filterDay } = props;
  const rows: ITableColumns<IStock>[] = useMemo(() => {
    return [
      {
        title: '',
        dataIndex: 'image',
        key: 'image',
        width: 30,
        render: (item, value) => {
          return <Avatar src={value} sx={{ width: 24, height: 24 }} alt={item.name} />;
        }
      },
      {
        title: 'Company Name',
        dataIndex: 'name',
        key: 'name',
        sortable: true,
        render: (item, value) => {
          return <Link to={`/?activeStock=${item.symbol}`}>{value}</Link>;
        }
      },
      {
        title: 'Total Mentions',
        dataIndex: 'totalMentions',
        key: 'totalMentions',
        sortable: true,
        render: (item, value) => {
          return numberWithCommas(value);
        }
      },
      {
        title: 'Positive Sentiment',
        dataIndex: 'positive',
        key: 'positive',
        sortable: true
      },
      {
        title: 'Negative Sentiment',
        dataIndex: 'negative',
        key: 'negative',
        sortable: true
      },
      {
        title: 'Latest PR',
        dataIndex: 'lastPrLink',
        key: 'lastPrLink',
        render: (item, value) => {
          if (!value) {
            if (filterDay === 'daily') {
              return 'NO PR today';
            }

            return 'NO PR this week';
          }

          return (
            <a href={value} target="_blank" rel="noopener noreferrer">
              PR
            </a>
          );
        }
      }
    ];
  }, [filterDay]);
  return (
    <CrudTable<IStock>
      columns={rows}
      noPagination
      requestUrl={API_ROUTES.AGENCY_OVERVIEW}
      queryOptions={{ filterDayType: filterDay }}
      rowKeyId="symbol"
      ExpandComponent={(record) => {
        return (
          <ExecutiveSummary wrapperClassName={styles.summaryWrapper} symbol={record.symbol} filterDay={filterDay} />
        );
      }}
    />
  );
};

export default StocksTable;
