import { SVGProps } from 'react';

export const RobotIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10 6.66665V3.33331H6.66667"
      stroke="#F04438"
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15 6.66669H4.99999C4.07952 6.66669 3.33333 7.41288 3.33333 8.33335V15C3.33333 15.9205 4.07952 16.6667 4.99999 16.6667H15C15.9205 16.6667 16.6667 15.9205 16.6667 15V8.33335C16.6667 7.41288 15.9205 6.66669 15 6.66669Z"
      stroke="#F04438"
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1.66667 11.6666H3.33334M16.6667 11.6666H18.3333M12.5 10.8333V12.5M7.5 10.8333V12.5"
      stroke="#F04438"
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
