import { Skeleton } from '@mui/material';
import { API_ROUTES } from 'const';
import useSWR from 'swr';
import { fetchWithConfig } from 'utils';

import Card from './Card';
import styles from './styles.module.scss';
import { ISummaryResponse } from './types';

const SummaryCards = () => {
  const { data, isLoading } = useSWR<ISummaryResponse>(API_ROUTES.AGENCY_OVERVIEW_SUMMARY, (url) =>
    fetchWithConfig({ url })
  );

  if (isLoading || !data) {
    return (
      <div className={styles.gridIcons}>
        {Array.from({ length: 6 }).map((_, index) => {
          return <Skeleton className={styles.skeleton} key={index} variant="rounded" height={135} />;
        })}
      </div>
    );
  }

  return (
    <div className={styles.gridIcons}>
      {(Object.keys(data) as (keyof ISummaryResponse)[]).map((key) => {
        return <Card key={key} dataKey={key} data={data[key]} />;
      })}
    </div>
  );
};

export default SummaryCards;
