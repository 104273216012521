import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { TableCell, TableRow } from '@mui/material';
import classNames from 'classnames';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BaseRecord, ITableColumns } from 'types';

import styles from './styles.module.scss';

const CrudTableRowList = <TData extends BaseRecord = any>(props: {
  data: TData[];
  columns: ITableColumns<TData>[];
  isLoading: boolean;
  mutate: any;
  ExpandComponent?: React.FC<TData>;
  rowKeyId?: string;
}) => {
  const { t } = useTranslation();
  const { data, columns, isLoading, mutate = () => {}, ExpandComponent, rowKeyId } = props;
  const columnsRender = [...columns];
  const [selected, setSelected] = useState<string | null>(null);

  const handleRowClick = (id: string) => {
    return () => {
      setSelected(id === selected ? null : id);
    };
  };

  if (ExpandComponent) {
    columnsRender.push({
      key: '',
      title: '',
      dataIndex: '',
      render: (data) => {
        const isExpanded = data._id === selected;
        return (
          <button
            className={classNames(styles.expandButton, { [styles.expanded]: isExpanded })}
            onClick={handleRowClick(data._id)}
          >
            {isExpanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </button>
        );
      }
    });
  }

  return (
    <>
      {data.map((row: TData, index: number) => {
        return (
          <React.Fragment key={`row_${index}`}>
            <TableRow hover role="checkbox" tabIndex={-1} {...(rowKeyId && { id: `table-row-${row[rowKeyId]}` })}>
              {columnsRender.map((column, indexCol) => {
                const align = columnsRender[indexCol]?.align ? { align: columnsRender[indexCol].align } : {};
                if (isLoading) {
                  return (
                    <TableCell key={indexCol} size={'small'} {...align}>
                      <>{indexCol}</>
                    </TableCell>
                  );
                }

                const colContent = row[column.key];

                const innerTd =
                  typeof column.render === 'function'
                    ? column.render(row, colContent, mutate, t)
                    : colContent?.toString() || '';
                return (
                  <TableCell key={indexCol} style={column.width ? { width: column.width } : {}} {...align}>
                    <>{innerTd}</>
                  </TableCell>
                );
              })}
            </TableRow>
            {selected === row._id && ExpandComponent && (
              <TableRow>
                <TableCell className={styles.expandedCell} colSpan={columnsRender.length}>
                  <ExpandComponent {...row} />
                </TableCell>
              </TableRow>
            )}
          </React.Fragment>
        );
      })}
    </>
  );
};

export default CrudTableRowList;
