import NavItem from 'components/SidebarNav/NavItem';
import { AgencyOverviewIcon, CompetitiveIcon, HomeIcon, ReportsIcon, ResponderIcon } from 'components/SvgComponents';
import { APP_ROUTS } from 'const';
import { useAppSelector } from 'hooks';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getUser } from 'store/slices/authSlice';
import { ITeamRole } from 'types';

import styles from './styles.module.scss';

type NavigationItem = { label: any; path: string; Icon: (props: React.SVGProps<SVGSVGElement>) => React.JSX.Element };

const MainNavigation = () => {
  const { t } = useTranslation();

  const user = useAppSelector(getUser);
  const [navigation, setNavigation] = useState<NavigationItem[]>([]);

  useEffect(() => {
    if (!user.team) {
      return;
    }

    const { availablePages } = user.team;

    setNavigation(() => [
      ...(user.role === ITeamRole.reseller || user.role === ITeamRole.admin
        ? [{ label: t('mainNavigation.agencyOverview'), path: APP_ROUTS.AGENCY_OVERVIEW, Icon: AgencyOverviewIcon }]
        : []),
      { label: t('mainNavigation.home'), path: APP_ROUTS.HOME, Icon: HomeIcon },
      ...(availablePages.competitive
        ? [{ label: t('mainNavigation.competitive'), path: APP_ROUTS.COMPETITIVE_ANALYSIS, Icon: CompetitiveIcon }]
        : []),
      ...(availablePages.responder
        ? [{ label: t('mainNavigation.responder'), path: APP_ROUTS.RESPONDER, Icon: ResponderIcon }]
        : []),
      ...(availablePages.reports
        ? [{ label: t('mainNavigation.reports'), path: APP_ROUTS.REPORTS, Icon: ReportsIcon }]
        : [])
      // { label: t('mainNavigation.recommendedActions'), path: APP_ROUTS.RECOMMENDED_ACTIONS, Icon: RecActionsIcon }
    ]);
  }, [t, user.team]);

  if (navigation.length <= 1) {
    return null;
  }

  return (
    <div className={styles.wrapper}>
      {navigation.map((item) => {
        return <NavItem key={item.path} title={item.label} to={item.path} Icon={item.Icon} />;
      })}
    </div>
  );
};
export default MainNavigation;
