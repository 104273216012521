import classNames from 'classnames';
import { AppButton, ButtonsType } from 'components';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Datasource } from 'types';
import { getDateSourceIcon } from 'utils';

import ListPopup from '../../_SharedComponents/ListPopup';
import styles from '../CreateYahooFinanceFilter/styles.module.scss';

interface ICreateStockTwitsFilterProps {
  stock?: string;
  onChange: (data: string[]) => void;
  defaultValue: string[];
}

const CreateStockTwitsFilter: React.FC<ICreateStockTwitsFilterProps> = (props) => {
  const [isPostsFilterPopupOpen, setIsPostsFilterPopupOpen] = useState(false);

  const Icon = getDateSourceIcon(Datasource.StockTwits);
  const { t } = useTranslation();
  const { onChange, defaultValue } = props;
  const onPopupSave = (value: string[]) => {
    onChange(value);
  };
  return (
    <>
      <div className={styles.row}>
        <div className={styles.sourceData}>
          <Icon />
          {t(`dataSource.${Datasource.StockTwits}`)}
        </div>
      </div>
      <div className={classNames(styles.row, styles.btn)}>
        <AppButton
          htmlType="button"
          btnType={ButtonsType.outlined}
          onClick={() => setIsPostsFilterPopupOpen(true)}
          label="Posts filter"
        />
      </div>
      {isPostsFilterPopupOpen && (
        <ListPopup
          onClose={() => setIsPostsFilterPopupOpen(false)}
          onSave={(value) => onPopupSave(value)}
          defaultValue={defaultValue}
          title="Filter posts containing the following phrases"
        />
      )}
    </>
  );
};

export default CreateStockTwitsFilter;
