import * as React from 'react';
import { SVGProps } from 'react';

export const ResponderIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="21" height="19" viewBox="0 0 21 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.99962 7.5H10.9996M6.99962 11H13.9996M11.4996 18C16.194 18 19.9996 14.1944 19.9996 9.5C19.9996 4.80558 16.194 1 11.4996 1C6.8052 1 2.99962 4.80558 2.99962 9.5C2.99962 10.45 3.15547 11.3636 3.443 12.2166C3.55119 12.5376 3.60529 12.6981 3.61505 12.8214C3.62469 12.9432 3.6174 13.0286 3.58728 13.1469C3.55677 13.2668 3.48942 13.3915 3.35472 13.6408L1.71906 16.6684C1.48575 17.1002 1.36909 17.3161 1.3952 17.4828C1.41794 17.6279 1.50337 17.7557 1.6288 17.8322C1.7728 17.9201 2.01692 17.8948 2.50517 17.8444L7.62619 17.315C7.78127 17.299 7.85881 17.291 7.92949 17.2937C7.999 17.2963 8.04807 17.3029 8.11586 17.3185C8.18478 17.3344 8.27145 17.3678 8.44478 17.4345C9.39283 17.7998 10.4228 18 11.4996 18Z"
      stroke="#98A2B3"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
