import { Datasource } from 'types';

export interface IPrItem {
  _id: string;
  title: string;
  link: string;
  stock: string;
  publishedAt: string;
  dayDate: string;
}

export enum ConversationSummaryStatusEnum {
  PENDING = 'pending',
  IN_PROGRESS = 'progress',
  COMPLETED = 'completed',
  FAILED = 'failed'
}

export interface IPrPoint extends IPrItem {
  x: number;
  additionalData?: IPrItem[];
}

export interface IConversationSummaryItem {
  _id: string;
  stock: string;
  dayDate: string;
  status: ConversationSummaryStatusEnum;
  dataSource: Datasource;
  summary: string;
}

export enum ChartSeriesName {
  price = 'price',
  chatter = 'chatter',
  volume = 'volume',
  index = 'index'
}

export interface ILinearChartResponse {
  series: ILinearChartSeriesItem[];
  dates: string[];
}

export interface IChatMarketData extends ILinearChartResponse {
  prLinks: IPrPoint[];
  summaries: IConversationSummaryItem[];
  interval: '1h' | '2h' | '3h' | '8h' | '12h' | '1d';
}

export interface ISentimentGraphData extends ILinearChartResponse {
  prLinks: IPrPoint[];
}

export interface ILinearChartSeriesItem {
  name: string;
  type: 'line' | 'column';
  data: number[];
  key?: string;
}

export interface ISentimentsSnapshotResponse {
  sentiments: ISentimentsSnapshotElement[];
  total: number;
}

export interface ISentimentsSnapshotElement {
  label: 'Positive' | 'Negative' | 'Neutral';
  count: number;
  percentage: string;
  sentimentValue: 1 | 2 | 3;
}
